
import React, { useEffect } from 'react'

import { GlobalStyles, Box } from '@mui/joy'
import { Outlet } from 'react-router-dom'
import { useLabel } from '@/contexts'

export function EmbedLayout() {
    const label = useLabel()
    return (
        <>
            <GlobalStyles
                defaultTheme="dark"
                styles={(theme) => {
                    return ({
                        ...label.style
                    })
                }}
            />
            <Box sx={{ ...label.style?.body }} data-joy-color-scheme="dark">
                <Outlet />
            </Box>
        </>
    )
}