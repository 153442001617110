import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/joy';

import i18n from '@/i18n'

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useHostname } from '@/contexts';
import { Img } from '@/components/Img';
import { HBox } from '@/components/HBox';
import { Link } from 'react-router-dom';
import { Link as JoyLink } from '@mui/joy';
import { AppleLogo, Money, SpotifyLogo, TidalLogo } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { UserView } from '@/components/UserView';
import { Do, Empty, ForEach } from '@/components/Syntax';
import { ReleaseCard } from '@/components/ReleaseCard';
import { Typography } from '@mui/material';
import { FeatureCard } from '@/components/FeatureCard';

export function ArtistView({ artistId }) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const [values, setValues] = useState({})

  const [isEligibleForAdvance, setEligibleForAnAdvance] = useState(false)

  const { data: artist, loading } = useResource({
    queryKey: ['artist', artistId],
    path: `/artist/${artistId}`
  })

  const { data: releasesData, loadingReleases } = useResource({
    queryKey: ['artist', artistId, 'releases'],
    path: `/artist/${artistId}/releases`
  })
  if (loading) {
    return <Loading />
  }

  if (artist) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <h1>{artist.name}</h1>
          {(artist.eligibility?.indexOf('advance') !== -1) && (
            <FeatureCard
              sx={{ alignSelf: 'stretch' }}
              as={Link}
              to="advance"
              Icon={Money}
              name={i18n.t('you-might-be-eligible-for-an-advance')}
              description={i18n.t('click-here-to-check')}
            />
          )}
          <Button
            component={Link}
            to={`/dashboard/artist/${artist.id}/edit`}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <Img src={artist.image_url} style={{ marginTop: 1, width: '120pt' }} />
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="links">
            {artist.spotify_id && (
              <JoyLink target="_blank" component={Link} to={`https://open.spotify.com/artist/${artist.spotify_id}`}>
                <SpotifyLogo weight="bold" size={28} />&nbsp;<Typography>Spotify</Typography>
              </JoyLink>
            )}
            {artist.apple_music_id && (
              <JoyLink component={Link} to={`https://music.apple.com/artist/${artist.apple_music_id}`}>
                <AppleLogo />&nbsp;<Typography>Apple Music</Typography>
              </JoyLink>
            )}
            {artist.tidal_id && (
              <JoyLink component={Link} to={`https://tidal.com/browse/artist/${artist.tidal_id}`}>
                <TidalLogo />&nbsp;<Typography>Tidal</Typography>
              </JoyLink>
            )}
            {artist.deezer_id && (
              <JoyLink component={Link} to={`https://deezer.com/en/artist/${artist.deezer_id}`}>
                <DeezerLogo />&nbsp;<Typography>Deezer</Typography>
              </JoyLink>
            )}
          </Box>
          <h3>{i18n.t('users')}</h3>
          <HBox>
            <ForEach collection={artist.members}>
              <Do>
                {member => (
                  <UserView key={member.id} user={member.user} />
                )}
              </Do>
              <Empty>
                <i>Artist has no users</i>
              </Empty>
            </ForEach>
          </HBox>
          <h3>{i18n.t('releases')}</h3>
          <Grid container spacing={2}>
            <ForEach collection={releasesData?.results}>
              <Do>
                {release => (
                  <Grid key={release.id} md={3}> 
                    <ReleaseCard
                      component={Link}
                      to={`/dashboard/release/${release.id}`}
                      {...release}
                    />
                  </Grid>
                )}
              </Do>
              <Empty>
                <i>Artist has not yet uploaded any releases</i>
              </Empty>
            </ForEach>
          </Grid>
        </Box>
      </Container>
    )
  }
}

export default function ArtistPage() {
  const { artistId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <ArtistView artistId={artistId} />
    </React.Suspense>
  )
}