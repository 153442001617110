import { Box, Button, Container, Typography } from '@mui/joy'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import i18n from '@/i18n'
import { submitAdvanceInquiry } from '@/actions/aloader'
import { TextField } from '@/components/InputField'

export function AdvancePage() {
    return (
        <AdvanceView />
    )
}

export function AdvanceView() {
    const navigate = useNavigate()
    const [email, setEmail] = useState(null)
    const [artistUrl, setArtistUrl] = useState(null)
    const [error, setError] = useState(null)
    const [estimating, setEstimating] = useState(false)

    const handleSubmit = event => {
        event.preventDefault()
        setEstimating(true)
        setError(null)
        submitAdvanceInquiry({
            email,
            artistUrl
        }).then(advanceInquiry => {
            setEstimating(false)
            navigate(`inquiry/${advanceInquiry.id}`)
        }).catch(e => {
            setEstimating(false)
            setError(e)
        })
        return false
    }

    return (
        <form method="POST" onSubmit={handleSubmit}>
            <Box sx={{ padding: 1 }}>
                <Box style={{ display: 'flex', gap: 10, width: '100%', alignItems: 'stretch', flexDirection: "column"}}>
                    <Typography sx={{ textAlign: 'center'}} level="h1" component="h1">Get a Cash Advance on Your Royalties</Typography>
                    <Typography sx={{ textAlign: 'center'}}>We offer cash advances on your future royalties, so you can get the money you need now without having to wait.</Typography>
                    <TextField required label={i18n.t('spotify-artist-url')} type="url" placeholder="https://open.spotify.com/artist/..." onChange={(event) => setArtistUrl(event.target.value)} />
                    <TextField required label={i18n.t('email')} type="email" placeholder="email" onChange={(event) => setEmail(event.target.value)} />
                    <Button variant="solid" color="primary" type="submit" loading={estimating}>{i18n.t('get-estimate')}</Button>
                </Box>
            </Box>
        </form> 
    ) 
}

export default AdvancePage