import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/joy';

import i18n from '@/i18n'

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useHostname } from '@/contexts';
import { HBox } from '@/components/HBox';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { UserView } from '@/components/UserView';
import { Do, Empty, ForEach } from '@/components/Syntax';
import { ReleaseCard } from '@/components/ReleaseCard';

  export function RecordingView({ recordingId }) {
    const hostname = useHostname()
    const navigate = useNavigate()

    const [values, setValues] = useState({})

  const { data: recording, loading } = useResource({
    queryKey: ['recording', recordingId],
    path: `/recording/${recordingId}`
  })

  const { data: releases, loadingReleases } = useResource({
    queryKey: ['recording', recordingId, 'releases'],
    path: `/recording/${recordingId}/releases`
  })
  if (loading) {
    return <Loading />
  }

  if (recording) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <h1>{recording.name}</h1>
          <h2>{recording.primary_genre?.name}</h2>
          <Button
            component={Link}
            to={`/dashboard/recording/${recording.id}/edit`}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <h3>{i18n.t('contributors')}</h3>
          <HBox>
            <ForEach collection={recording.recording_contributors}>
              <Do>
                {contributor => (
                  <UserView key={contributor.id} user={{ username: contributor.person.name }} />
                )}
              </Do>
              <Empty>
                <i>Recording has no users</i>
              </Empty>
            </ForEach>
          </HBox>
          <h3>{i18n.t('payees')}</h3>
          <HBox>
            <ForEach collection={recording.recording_payees}>
              <Do>
                {recordingPayee => (
                  <UserView key={recordingPayee.id} user={{ username: recordingPayee.payee.name }} />
                )}
              </Do>
              <Empty>
                <i>Recording has no users</i>
              </Empty>
            </ForEach>
          </HBox>
          <h3>{i18n.t('artists')}</h3>
          <HBox>
            <ForEach collection={recording.recording_artists}>
              <Do>
                {recordingArtist => (
                  <UserView key={recordingArtist.id} user={{ username: recordingArtist.artist.name }} />
                )}
              </Do>
              <Empty>
                <i>Recording has no artists</i>
              </Empty>
            </ForEach>
          </HBox>
          <h3>{i18n.t('in-releases')}</h3>
          <Grid container spacing={2}>
            <ForEach collection={releases?.results }>
              <Do>
                {(release, i) => (
                  <Grid key={release.id} md={3}> 
                    <ReleaseCard
                      sx={{ p: 0, transitionDelay: `${i * 100}ms`}}
                      component={Link}
                      to={`/dashboard/release/${release.id}`}
                      {...release}
                    />
                  </Grid>
                )}
              </Do>
              <Empty>
                <i>Recording has not yet uploaded any releases</i>
              </Empty>
            </ForEach>
          </Grid>
        </Box>
      </Container>
    )
  }
}

export default function RecordingPage() {
  const { recordingId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <RecordingView recordingId={recordingId} />
    </React.Suspense>
  )
}