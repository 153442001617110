import { Box, styled } from '@mui/joy';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
    
const TabBarRoot = styled(
    Box,
    {
        slot: 'root',
        name: 'TabBar'
    }
)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 10
}))

const TabBarTabFrame = styled(
    Box,
    {
        slot: 'tab',
        name: 'TabBar'
    }
)(({ theme }) => ({
    display: 'flex',
    textDecoration: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&.TabBarTab-active': {
       color: theme.palette.primary.solidActiveBg,
       '.TabBarTab-line': {
            visibility: 'visible'
       }
    }
}))

TabBarTabFrame.defaultProps = {
    component: NavLink
}

export const TabBarTabLine = styled(
    Box,
    {
        slot: 'line',
        name: 'TabBarTab'
    }
)(({ theme }) => ({
    width: '100pt',
    height: '10pt',
    background: theme.palette.primary.solidActiveBg,
    visibility: 'hidden'
}))

export function TabBarTab({
    children,
    ...props
}) {
    return (
        <TabBarTabFrame className={({ isActive, pending }) => isActive ? 'TabBarTab-active' : ''} {...props}>
            {children}
            <TabBarTabLine className="TabBarTab-line" />    
        </TabBarTabFrame>
    )
}

export function TabBar({
    children,
    orientation='horizontal'
}) {
    return (
        <TabBarRoot>
            {children}
        </TabBarRoot>
    )
}