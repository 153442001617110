import React from 'react'

import { Route, Outlet } from "react-router-dom";

import AdvanceLayout from "../advance/layout";
import AdvancePage from '.';

import AdvanceInquiryFundingPage from './inquiry/[id]/funding';
import { Navigate } from 'react-router-dom';

export default (
    <Route path="advance" element={<AdvanceLayout />}>
        <Route index element={<AdvancePage />} /> 
        <Route path="inquiry" element={<Outlet />}>
            <Route path=":advanceInquiryId" element={<Outlet />}>
                <Route index element={<Navigate to="funding" replace />} />
                <Route path="funding" element={<AdvanceInquiryFundingPage />} />
            </Route>
        </Route> 
    </Route>
)
