import React, { useEffect } from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';

import Divider from '@mui/joy/Divider';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import { closeSidebar } from '../utils';
import { Link } from 'react-router-dom';
import { NavLink } from '@/components/NavLink';
import { ListItemDecorator } from '@mui/joy';
import { useLocation } from 'react-router-dom';

import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { User } from '@phosphor-icons/react';
import { useLabel } from '@/contexts';

function Toggler({
  value = false,
  renderToggle,
  children
}) {
  const [open, setOpen] = React.useState(value);
  useEffect(() => {
    setOpen(value)
  }, [value])
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar({
  menuItems
}) {
  const label = useLabel()
  const location = useLocation()
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 1000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 0,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2, 
        borderTop: 'none'
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          display: 'flex',
          flexDirection: 'column',
          
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      > 
        <Box
          sx={{
            aspectRatio: '16/9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={label?.is_branded && label?.logotype_url ? label.logotype_url : "/images/ALOADED-menu-logo.svg"} style={{ width: label?.is_branded && label?.logotype_url ? '80%' : undefined}} />
        </Box>
        <List sx={{ gap: 0.1, '--joy-spacing': '0pt' }}>
          {menuItems.map(({ id, items, href, Icon = User, text }, i) => (
            <ListItem sx={{ transitionDelay: `${i * 10}ms`}} nested key={id}>
              {items?.length > 0 ? (
                <Toggler
                  value={location.pathname.indexOf(href) === 0}
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton
                      onClick={() => setOpen(!open || (location.pathname.indexOf(href) === 0))}  
                      selected={location.pathname.indexOf(href) === 0}
                      component={NavLink}
                      to={href}
                    >
                      <ListItemDecorator sx={{ p: 0 }}>
                        <Icon weight="bold" size={22} />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="body-xs" fontWeight={600}>{text}</Typography>
                      </ListItemContent>
                      {items?.length > 0 && <KeyboardArrowDownIcon
                        sx={{ display: 'none', transform: open || location.pathname.indexOf(href) === 0? 'rotate(180deg)' : 'none' }}
                      />}
                    </ListItemButton> 
                  )}
                >
                  <List sx={{ gap: 0.5, marginLeft: 1 }}>
                    {items.map(({ id, text, icon: Icon, href }) => (
                      <ListItem
                        key={id}
                        component={NavLink}
                        to={href}
                      >
                        <ListItemButton
                          selected={location.pathname.indexOf(href) === 0}
                        >
                          <ListItemDecorator sx={{ p: 0 }}>
                            <Icon weight="bold" size={22} />
                          </ListItemDecorator>
                          <ListItemContent>
                            <Typography level="body-xs" fontWeight={600}>{text}</Typography>
                          </ListItemContent> 
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Toggler>
              ) : (
                <ListItem
                  key={id}
                  component={NavLink}
                  to={href}
                >
                  <ListItemButton
                    selected={location.pathname.indexOf(href) === 0}
                  >
                    <ListItemDecorator sx={{ p: 0 }}>
                      <Icon weight="bold" size={22} />
                    </ListItemDecorator>
                    <ListItemContent>
                      <Typography level="body-xs" fontWeight={600}>{text}</Typography>
                    </ListItemContent> 
                  </ListItemButton>
                </ListItem>
              )}
            </ListItem>
          ))}
        </List> 
      </Box>
      <Box sx={{ display: 'flex', flex: 1 }} />
    </Sheet>
  );
}