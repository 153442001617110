import { ErrorView } from '@/components/ErrorView'
import { LoadingPage } from '@/components/LoadingPage'
import { useResource } from '@/hooks'
import { Button, Container, Typography } from '@mui/joy'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import i18n from '@/i18n'
import { submitArtistAdvanceInquiry } from '@/actions/aloader'

export function ArtistAdvancePage() {
    const { artistId } = useParams()
    return (
        <ArtistAdvanceView artistId={artistId} />
    )
}

export function ArtistAdvanceView({
    artistId
}) {
    const navigate = useNavigate()
    const [error, setError] = useState(null)
    const [estimating, setEstimating] = useState(false)
    const { data: artist, isLoading } = useResource({
        queryPath: ['artist', artistId],
        path: `/artist/${artistId}`
    })
    const handleSubmit = event => {
        event.preventDefault()
        setEstimating(true)
        setError(null)
        submitArtistAdvanceInquiry({
            artist_id: artist.id
        }).then(advanceInquiry => {
            setEstimating(false)
            navigate(`inquiry/${advanceInquiry.id}`)
        }).catch(e => {
            setEstimating(false)
            setError(e)
        })
        return false
    }
    if (isLoading) {
        return <LoadingPage />
    }
    if (artist) {
        return (
            <Container>
                <Typography>{artist.name}</Typography>
                <form method="POST" onSubmit={handleSubmit}>
                    <Button variant="solid" color="primary" type="submit" loading={estimating}>{i18n.t('get-estimate')}</Button>
                </form>
            </Container>
        )
    } else {
        return <ErrorView />
    }
}

export default ArtistAdvancePage