import React, { useState, useCallback } from 'react';

import { Input, Table, Button, Box, Avatar, Modal, ModalDialog, Typography, ModalClose, Switch } from '@mui/joy';
import { Check, MagnifyingGlass, X } from '@phosphor-icons/react';

import { useInfiniteResource, useResource } from '@/hooks';
import { Img } from '@/components/Img';
import { InputField, InputFieldFrame } from './InputField';
import { isSubject } from '@/utils'

import { Loading } from './Loading';
import { VBox } from './VBox';
import i18n from '@/i18n'
import { SelectNode } from './SelectNode';
import { CreateNodeForm } from './CreateNodeForm';
import { HBox } from './HBox';
import { useEffect } from 'react';

export function SearchNodeList({
  nodeType,
  endpoint,
  selectedNodes: defaultSelectedNodes,
  fields = [],
  attributes = [],
  canCreate=false,
  onClose,
  allLabel = "allLabel",
  addLabel = "addLabel",
  ownLabel = "ownLabel",
  onChange = () => console.warn("SearchNodeList onchange not implemented"),
  onSubmit = () => console.warn('onSubmit')
}) {
  const [selectedNodes, setSelectedNodes] = useState(defaultSelectedNodes)

  const [createdNodes, setCreatedNodes] = useState([])


  useState(() => {
    setSelectedNodes(defaultSelectedNodes)
  }, [defaultSelectedNodes])
  const [creatingNewNode, setCreatingNewNode] = useState(false)
  const [q, setQ] = useState('') 
  const { data, hasNextPage, fetchNextPage, refetch, loading } = useInfiniteResource({
    queryKey: [nodeType, q],
    key: [`${nodeType}?q=${q}`], path: endpoint ? `/${endpoint}` : `/${nodeType}`,
    query: {
      q
    }
  })
  useEffect(() => {
    refetch()
  }, [q])
  const toggleSelectNode = (node) => {
    const existingNode = selectedNodes.find(n => n?.[nodeType]?.id === node.id)
    let newSelectedNodes = [...selectedNodes]
    if (!existingNode) {
      newSelectedNodes.push({
        [nodeType]: node
      })
    } else {
      newSelectedNodes = selectedNodes.filter(n => n?.[nodeType]?.id !== node.id)
    }
    setSelectedNodes(newSelectedNodes)
  }

  const removeSelectedNode = node => {
    const newSelectedNodes = selectedNodes.filter(n => n?.[nodeType]?.id !== node?.[nodeType].id)
    setSelectedNodes(newSelectedNodes)
  }

  const handleSubmit = useCallback(() => {
    onChange(selectedNodes)
    onSubmit(selectedNodes)
  }, [selectedNodes])

  const handleNodeCreated = newNode => {
    refetch()
    if (newNode instanceof Object) {
      setCreatedNodes([newNode])
    }
  }

  let nodes = data?.pages.flat(1)

  if (nodes instanceof Array) {
    nodes = [...createdNodes, ...nodes]
  }

  return (
    <>
      <InputField
        label={ownLabel}
      >
        <InputFieldFrame sx={{ overflow: 'scroll', flex: 1 }}>
          <Table stickyHeader>
            <tbody>
              {selectedNodes?.length > 0 ? selectedNodes.map(node => (
                <tr key={node.id}>
                  <td width={0}>
                    {isSubject ?
                      <Avatar src={node?.[nodeType]?.imageUrl} size={"sm"} />
                    :
                      <Img src={node?.[nodeType]?.imageUrl} sx={{ height: '10pt' }} />
                    }
                  </td>
                  <td>{node?.[nodeType].name ?? node?.[nodeType]?.username}</td>
                  <td>
                    {attributes.map(attribute => {
                      switch (attribute.type) {
                      case "belongsTo":
                        return (
                          <SelectNode
                            defaultValue={node?.[attribute.id]}
                            nodeType={attribute.nodeType}
                            onChange={(value) => {
                              const index = selectedNodes.indexOf(node)
                              const newSelectedNodes = selectedNodes.filter(n => n?.id !== node?.id)
                              node[attribute.id] = value
                              newSelectedNodes.splice(index, 0, node)
                              onChange(newSelectedNodes)
                            }}
                          />
                        )
                      case "belongsToMany":
                        return (
                          <SelectNode
                            multiple
                            placeholder={attribute.placeholder}
                            node={node?.[attribute.id]}
                            nodeType={attribute.nodeType}
                            onChange={(value) => {
                              if (value instanceof Array) {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n?.id !== node?.id)
                                node[attribute.id] = value
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }
                            }}
                          />
                        )
                      case "percentage":
                        return (
                          <InputFieldFrame>
                            <Input
                              variant="plain"
                              type={attribute.type}
                              defaultValue={node?.[attribute.id]}
                              placeholder={attribute.placeholder}
                              sx={{ p: 0, flex: 1, '&.Mui-focused': { '--Input-focusedHighlight': 'none' }, 'input': { textAlign: 'right !important' } }}
                              onChange={(event) => {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n?.id !== node?.id)
                                node[attribute.id] = parseFloat(event.target.value)
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }}
                            />
                            <Typography sx={{ p: 1}}>%</Typography>
                          </InputFieldFrame>
                        );
                      case "bool":
                        return (
                          <HBox>
                            <Switch
                              defaultChecked={node[attribute.id]}
                              onChange={(event) => {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n !== node)
                                node[attribute.id] = event.target.checked
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }}
                            />
                            <Typography>{node[attribute.id] === true ? (attribute.onLabel ?? attribute.label) : (attribute.offLabel ?? attribute.label)}</Typography>
                          </HBox>
                        )
                      case "number":
                      default:
                        return (
                          <Input
                            type={attribute.type}
                            defaultValue={node?.[attribute.id]}
                            onChange={(event) => {
                              const index = selectedNodes.indexOf(node)
                              const newSelectedNodes = selectedNodes.filter(n => n !== node)
                              node[attribute.id] = event.target.value
                              newSelectedNodes.splice(index, 0, node)
                              onChange(newSelectedNodes)
                            }}
                          />
                        )
                      }
                    })}
                  </td>
                  <td style={{ textAlign: 'right'}}>
                    <Button variant="outlined" onClick={() => removeSelectedNode(node)}>{i18n.t('remove')}</Button>
                  </td>
                </tr>
              )): (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={3}>
                    No {nodeType}s added
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </InputFieldFrame>
      </InputField>
      <InputField
        label={allLabel}
      >
        <InputFieldFrame>
          <VBox>
            <HBox>
              <Input
                sx={{ flex: 1, margin: 1}}
                startDecorator={<MagnifyingGlass />}
                variant="outlined"
                defaultValue={q}
                onChange={(event) => {
                  setQ(event.target.value)
                }}
              />
              {canCreate && ( 
                <Button
                  variant="solid"
                  color="primary"
                  sx={{ marginRight: 1, marginLeft: 0 }}
                  onClick={() => setCreatingNewNode(true)}
                >
                  {i18n.t('create-new-node', { nodeType })}
                </Button>   
              )}
            </HBox>
            {loading ? 
              <Loading />
            : nodes instanceof Array ?
              <Box sx={{ overflow: 'scroll' }}>
                <Table stickyHeader>
                  <tbody>
                    {nodes?.length > 0 ? nodes.map(node => (
                      <tr key={node.id}>
                        <td width={0}>
                          {isSubject ?
                            <Avatar src={node.imageUrl} size={"sm"} />
                          :
                            <Img src={node.imageUrl} sx={{ height: '10pt' }} />
                          }
                        </td>
                        <td>{node.name ?? node.username}</td>
                        <td style={{ textAlign: 'right' }}>
                          <Button variant="outlined" onClick={() => toggleSelectNode(node)}>{i18n.t(selectedNodes.find(n => n[nodeType]?.id == node.id) ? 'remove' : 'add')}</Button>
                        </td>
                      </tr>
                    )): (
                      <tr>
                        <td style={{ textAlign: 'center' }} colSpan={3}>
                          No {nodeType}s found
                        </td>
                      </tr>
                    )}
                    {hasNextPage && (
                      <tr>
                        <td colSpan={3} style={{ textAlign: 'center' }}>
                          <Button
                            variant="outlined"
                            onClick={() => fetchNextPage()}
                          >
                            {i18n.t('load-more')}
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Box>
            : <>Error</>}
          </VBox>
        </InputFieldFrame>
      </InputField>
      <HBox>
        <Button
          variant="solid"
          startDecorator={<Check />}
          onClick={handleSubmit}
          sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
        >{i18n.t('done')}</Button>
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          startDecorator={<X />}
          onClick={onClose}
          sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
        >{i18n.t('cancel')}</Button>
      </HBox>
      <Modal open={creatingNewNode} onClose={() => setCreatingNewNode(false)}>
        <ModalDialog>
          <ModalClose />
          <CreateNodeForm
            fields={fields}
            nodeType={nodeType}
            defaultName={q}
            onNodeCreated={handleNodeCreated}
            onClose={() => setCreatingNewNode(false)}
          />
        </ModalDialog>
      </Modal>
    </>
  )
}