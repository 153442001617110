import React from 'react';

import moment from 'moment'

import Box from '@mui/joy/Box';

import { useUser, useLabel, useRole } from '@/contexts';

import Sidebar from '@/components/Sidebar';
import Header from '@/components/Header';
import { Bell, Broadcast, CardsThree, Disc, ExclamationMark, Megaphone, Money, MusicNote, MusicNotesPlus, Tag, Toolbox, TShirt, UsersThree, Waveform, X } from '@phosphor-icons/react';

import { createTeleporter } from 'react-teleporter';

import { User } from '@phosphor-icons/react';

import i18n from '@/i18n'
import { SquaresFour, ChartBar, Smiley } from '@phosphor-icons/react';
import { Outlet } from 'react-router-dom';
import { Avatar, Button, Container, styled, Typography } from '@mui/joy';
import { AppHeader } from '@/components/AppHeader';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@/actions/login';
import { useSessionContext } from '@/contexts/SessionContext';

export const TitleBar = createTeleporter()

export function DashboardLayout({ children }) {

  const navigate = useNavigate()
  const user = useUser()

  const label = useLabel()

  const role = useRole()

  const { setUser } = useSessionContext()

  console.log("children", children)

  let menuItems = []

  useEffect(() => {
    getCurrentUser().then((data) => {
      if (data) {
        if (!data.code) {
          if(data?.label) {
            setUser(data)
          } else {
            navigate(`/notauthorized`, { replace: true })
          }
        } else {
          navigate(`/accounts/login`)
        }
      }
    }).catch(e => {
      console.error(e)
      navigate(`/accounts/login`)
    })
  }, [])

  if (label) {
    menuItems = [ 
      {
        "id": "dashboard",
        "href": "/dashboard/overview",
        "Icon": SquaresFour,
        "text": i18n.t("dashboard")
      },
      {
        "id": "statistics",
        "Icon": ChartBar,
        "href": "/dashboard/statistics",
        "text": i18n.t("statistics"),
        "query": {
          "start": moment().subtract(28, 'days').format('YYYY-MM-DD'),
          "end": moment().format('YYYY-MM-DD'),
        }
      },
      {
        "id": "artists",
        "href": "/dashboard/artists",
        "text": i18n.t('artists'),
        "Icon": Smiley
      },
      {
        "id": "releases",
        "href": "/dashboard/releases",
        "text": i18n.t('releases'),
        "Icon": CardsThree
      },
      {
        "id": "recordings",
        "href": "/dashboard/recordings",
        "text": i18n.t('tracks'),
        "Icon": Waveform
      },
      /*{
        "id": "marketing",
        "Icon": Megaphone,
        "text": i18n.t('marketing'),
        "href": "/dashboard/marketing",
        "items": [
          {
            "id": "promotion",
            "href": "/dashboard/marketing/promotion",
            "text": i18n.t('promotion'),
            "icon": UsersThree
          },
          {
            "id": "pre-save",
            "href": "/dashboard/marketing/pre-save",
            "text": i18n.t('pre-save'),
            "icon": MusicNotesPlus
          },
          {
            "id": "merch",
            "href": "/dashboard/marketing/merch",
            "text": i18n.t('merch'),
            "icon": TShirt
          },
          {
            "id": "pitch",
            "href": "/dashboard/marketing/pitch",
            "text": i18n.t('pitch'),
            "icon": Broadcast
          }
        ]
      },*/
      ...(['OWNER', 'ADMIN'].indexOf(role?.role) !== 1 ? ([
        {
          "id": "admin",
          "text": i18n.t('admin'),
          "href": "/dashboard/admin",
          "Icon": Toolbox,
          "items": [
            /*{
              "id": "labels",
              "text": i18n.t('labels'),
              "href": "/dashboard/admin/labels",
              "icon": Tag
            },
            {
              "id": "people",
              "text": i18n.t('people'),
              "href": "/dashboard/admin/people",
              "icon": Smiley
            },
            {
              "id": "payees",
              "text": i18n.t('payees'),
              "href": "/dashboard/admin/payees",
              "icon": Smiley
            },*/
            {
              "id": "users",
              "text": i18n.t('users'),
              "href": "/dashboard/admin/users",
              "icon": User
            }
          ]
        }
      ]) : []),
      /*..(user?.label?.payeeUsers?.length > 0 ? ([({
        "Icon": Money,
        "text": i18n.t('payments'),
        "items": user?.label?.payeeUsers?.map(payeeUser => ({
          "icon": Money,
          "href": `/dashboard/payees/${payeeUser.payee.id}`
        }))
      })]) : []),*/
    ]
  }

  const menuId = "menuId"
  
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar menuItems={menuItems} />
      <Header />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100dvh',
          overflow: 'auto',
        }}
      >
        <AppHeader user={user} />
        <Box sx={{ overflow: 'scroll', flex: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardLayout;