import React, { useState } from 'react';

import { Button, Table, Modal, ModalDialog, Box, ModalClose, Avatar, Switch } from '@mui/joy'

import { InputField, InputFieldFrame } from './InputField';
import { SearchNodeList } from './SearchNodeList';

import i18n from '@/i18n'

import { Img } from './Img';

import { isSubject } from '@/utils';
import { useEffect } from 'react';
import { SelectRelatedNode } from './SelectRelatedNode';
import { CreateNodeForm } from './CreateNodeForm';
import { Typography } from '@mui/material';
import { HBox } from './HBox';

export function SelectRelatedNodes({
  label,
  fields,
  heading,
  allLabel,
  addLabel,
  ownLabel,
  placeholder,
  errors,
  canCreate=false,
  nodes,
  attributes = [],
  onChange,
  readOnly,
  nodeType,
  ownerNodeType,
  ownerNodeId
}) {
  const [isAddingNodes, setAddingNodes] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState(nodes)
  
  useEffect(() => {
    setSelectedNodes(nodes)
  }, [nodes])
  const handleChange = (nodes) => {
    setSelectedNodes(nodes)
  }

  const handleSubmitSetNodes = (selectedNodes) => {
    onChange(selectedNodes)
    setAddingNodes(false)
  }

  return (
    <>
      <h3>{heading}</h3>
      <InputField
        label={label}
        errors={errors}
      >
        <InputFieldFrame sx={{ padding: '16px 16px' }}>
          <Table
            sx={{ width: '100%', 'td': { p: 0} }}
          >
            <tbody>
              {selectedNodes?.length > 0 ?
                <>
                  {selectedNodes?.map(node => (
                    <tr key={node.id}>
                      <td width={40}>
                        {isSubject(nodeType) ?
                          <Avatar src={node[nodeType].imageUrl} size="sm" style={{ marginRight: 10 }} />
                        :
                          <Img src={node[nodeType].imageUrl} sx={{ height: '10pt' }} style={{ height: '10pt', marginRight: 10 }} />
                        }
                      </td>
                      <td>{node[nodeType]?.name}</td>
                      {attributes.map(attribute => {
                        return (
                          <td key={attribute.id}>
                            {(() => {
                              switch (attribute.type) {
                              case "belongsTo":
                                return (
                                  node[attribute.id] instanceof Array ? (
                                    node[attribute.id].map(n => n.name).join(', ')
                                  ) : node[attribute.id] instanceof Object ? (
                                    node[attribute.id].name
                                  ) : ''
                                )
                              case "belongsToMany":
                                return (
                                  node[attribute.id] instanceof Array ? (
                                    node[attribute.id].map(n => n.name).join(', ')
                                  ) : node[attribute.id] instanceof Object ? (
                                    node[attribute.id].name
                                  ) : ''
                                )
                              case "percentage":
                                return (
                                  <Typography sx={{ textAlign: 'right', marginRight: 20 }}>{(node[attribute.id] ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</Typography>
                                )
                                case "bool":
                                  return (
                                    <HBox>
                                      <Switch readOnly={readOnly} defaultChecked={node[attribute.id]} /> {node[attribute.id] ? (attribute.onLabel ?? attribute.label) : (attribute.offLabel ?? attribute.label)}
                                    </HBox>
                                  )
                              default:
                                return (
                                  node[attribute.id] instanceof Array ? (
                                    node[attribute.id].map(n => n.name).join(', ')
                                  ) : node[attribute.id] instanceof Object ? (
                                    node[attribute.id].name
                                  ) : ''
                                )
                              }
                            })()}
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </>
              :
                <tr>
                  <td colSpan={2}>
                    <Typography sx={{ fontFamily: "DM Mono" }}>{placeholder}</Typography>
                  </td>
                </tr>
              }
            </tbody>
          </Table>
          {!readOnly && <Box sx={{  display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setAddingNodes(true)}
            >
              {i18n.t(nodes?.length > 0 ? 'edit' : 'add')}
            </Button>
          </Box>}
        </InputFieldFrame>
      </InputField>
      <Modal open={isAddingNodes} onClose={() => setAddingNodes(false)}>
        <ModalDialog>
          <ModalClose />
          <SearchNodeList
            fields={fields}
            nodeType={nodeType}
            onClose={() => setAddingNodes(false)}
            attributes={attributes}
            canCreate={canCreate}
            selectedNodes={selectedNodes}
            allLabel={allLabel}
            addLabel={addLabel}
            ownLabel={ownLabel}
            onChange={handleChange}
            onSubmit={handleSubmitSetNodes}
          />
        </ModalDialog>
      </Modal>
    </>
  )
}