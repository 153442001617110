import React, { useEffect } from 'react';

import moment from 'moment/moment';

import { useLabel, useUser } from '@/contexts'; 
import { Box, Typography } from '@mui/joy';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@/actions/login';
import { TabBar, TabBarTab } from '@/components/TabBar';

import i18n from '@/i18n'

export default function AccountsLayout({ children }) {
    const label = useLabel()

    return (
      <ws-block>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ flex: 1 }}></Box>
          <img src={label?.logotype_url} width="300pt" />
          <TabBar>
            <TabBarTab to="/accounts">
              <Typography level="h1" component="h1">
                {i18n.t('distribution')}
              </Typography>
            </TabBarTab>
            <TabBarTab to="/advance">
              <Typography level="h1" component="h1">
                {i18n.t('advance')}
              </Typography>  
            </TabBarTab>            
          </TabBar>
          <Box style={{ flex: 1 }}></Box>
          <Outlet />
          <Box sx={{ flex: 1 }}></Box>
          <small style={{ margin: 10, opacity: 0.5 }}>Copyright &copy; {moment().format('YYYY')} {label?.name}. All Rights Reserved.</small>
        </Box>
      </ws-block>
    )
}