import React from 'react'

import { VBox } from '@/components/VBox';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n'

import { Statistics } from '@/components/Statistics';

import { useSearchParam, useResource } from '@/hooks';
import moment from 'moment';
import { Box, Container, Button, Option, Select } from '@mui/joy';
import { useEffect } from 'react';
import { ToggleButtonBox } from '@/components/ToggleButtonBox';
import { ToggleButton } from '@/components/ToggleButton';
import { CloudDownload } from '@mui/icons-material';
import { downloadStatistics } from '@/actions/aloader';
import { useLabel } from '@/contexts';
import { LoadingPage } from '@/components/LoadingPage';
import { ErrorView } from '@/components/ErrorView';

const METRICS = ['streams', 'saves']

export function StatisticsContainer({
  start: defaultStart,
  end: defaultEnd,
  dataKey: defaultDataKey = 'streams',
  period: defaultPeriod = '7-days'
}) {
  const label = useLabel()
  const [end, setEnd] = useSearchParam('toDate', defaultEnd ?? moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [start, setStart] = useSearchParam('fromDate', defaultStart ?? moment().subtract(8, 'day').format('YYYY-MM-DD'))
  const [dataKey, setDataKey] = useSearchParam('dataKey', defaultDataKey)

  const [period, setPeriod] = useSearchParam('period', defaultPeriod)
  useEffect(() => {
    switch (period) {
      case "7-days":
        setEnd(moment().subtract(1, 'day').format('YYYY-MM-DD'))
        setStart(moment().subtract(8, 'day').format('YYYY-MM-DD'))
        break;
      case "28-days":
        setEnd(moment().subtract(1, 'day').format('YYYY-MM-DD'))
        setStart(moment().subtract(29, 'day').format('YYYY-MM-DD'))
        break;
    }
  }, [period])
  const { data, isLoading } = useResource({
    queryKey: ['statistics', start, end],
    path: `/statistics`,
    query: {
      start,
      end
    }
  })

  const handleDownloadStatsClick = event => {
    downloadStatistics({
      query: {
        start,
        end,
        labelId: label.id  
      }
      
    })
  }

  return (
    <VBox>
      <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: 1 }}>
        <Select
          sx={{ transitionDelay: `300ms`, minWidth: 200 }}
          placeholder={i18n.t('period')}
          defaultValue={period}
          onChange={(event, value) => {
            setPeriod(value)
          }}
        >
          <Option value="7-days">{i18n.t('7-days')}</Option>
          <Option value="28-days">{i18n.t('28-days')}</Option>
        </Select>
        <ToggleButtonBox>
          {METRICS.map(metric => (
            <ToggleButton
              active={metric === dataKey}
              variant="outlined"
              onClick={() => {
                setDataKey(metric)
              }}
            >{i18n.t(metric)}</ToggleButton>
          ))}
        </ToggleButtonBox>
        <Button
          startDecorator={<CloudDownload />}
          variant="outlined"
          onClick={handleDownloadStatsClick}
        >
          {i18n.t('download-stats')}
        </Button>
      </Box>
      <HBox>
        {isLoading ?
          <LoadingPage />
        
         : data ? (
          <Statistics
            dataKey={dataKey}
            start={start}
            end={end}
            data={data}
          />
        ) : <ErrorView />}
      </HBox>
    </VBox>
  )
}

export default function StatisticsPage() {
  return (
    <Container>
      <VBox>
        <h1>{i18n.t('statistics')}</h1>
        <StatisticsContainer />
      </VBox>
    </Container>
  )
}