import { submitAdvanceInquiryForReview, submitAdvanceInquiryFunding } from '@/actions/aloader'
import { ErrorView } from '@/components/ErrorView'

import { LoadingPage } from '@/components/LoadingPage'
import { useResource } from '@/hooks'
import { Button, ButtonGroup, Container, Typography } from '@mui/joy'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import i18n from '@/i18n'

export function AdvanceInquiryFundingPage() {
    const { advanceInquiryId } = useParams()
    return (
        <AdvanceInquiryFundingView
            advanceInquiryId={advanceInquiryId}
        />
    )
}

export default AdvanceInquiryFundingPage

export function AdvanceInquiryFundingView({
    advanceInquiryId
}) {
    const navigate = useNavigate()
    const [estimating, setEstimating] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [contractLengthInYears, setContractLengthInYears] = useState(3)
    const [isCalculating, setCalculating] = useState(false)
    const [interestedInBuyout, setInterestedInBuyout] = useState(false)
 
    const { data: advanceInquiry, isLoadingAdvanceInquiry } = useResource({
        queryKey: ['advance', 'inquiry', advanceInquiryId],
        path: `/advance/inquiry/${advanceInquiryId}`
    })
    useEffect(() => {
        setContractLengthInYears(advanceInquiry?.contractLengthInYears)
        setInterestedInBuyout(advanceInquiry?.interestedInBuyout)
    }, [advanceInquiry])

    const handleSubmit = event => {
        event.preventDefault()
        setEstimating(true)
        setError(null)
        submitAdvanceInquiryFunding(
          advanceInquiryId,
          {
            contractLengthInYears,
            interestedInBuyout
          }
        ).then((result) => {
            submitAdvanceInquiryForReview(advanceInquiryId, {
                ableToMoveCatalogue: true,
                contractLengthInYears,
                files: [],
                interestedInBuyout,
                isMobile,
                distributorName: ''
            }).then(result => {
                setEstimating(false)
                navigate(`submitted`)
            }).catch(e => {
                setEstimating(false)
                setError(e)
            })
        })
        return false
    }
    if (isLoadingAdvanceInquiry) {
        return <LoadingPage />
    }
    if ( advanceInquiry) {
        return ( 
                <form method="POST" onSubmit={handleSubmit}>
                    <div style={{ padding: 0, flex: 1 }}>
                        <div style={{ margin: 20 }}>
                            <Typography level="h1">
                                {i18n.t(interestedInBuyout ? 'you-are-eligible-for-a-buyout-of' : 'you-are-eligible-for-an-advance-of', `€${(interestedInBuyout ? (advanceInquiry?.buyout ?? 0) : (advanceInquiry?.advanceByYears?.[contractLengthInYears] ?? 0)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0, currency: 'EUR' })}`)}
                            </Typography>
                        </div>
                        <div style={{ margin: 20 }}>
                            <Typography>Choose funding option below and submit. We will be in touch as soon as possible.</Typography>
                        </div>
                        <ButtonGroup>
                            <Button
                                color="primary"
                                variant={!interestedInBuyout && contractLengthInYears === 3 ? 'solid' : 'outlined'}
                                onClick={() => {
                                    setContractLengthInYears(3)
                                    setInterestedInBuyout(false)
                                }}
                            >
                                {i18n.t('x-year-distribution-deal', 3)}
                            </Button>
                            <Button
                                color="primary"
                                variant={!interestedInBuyout && contractLengthInYears === 5 ? 'solid' : 'outlined'}
                                onClick={() => {
                                    setContractLengthInYears(5)
                                    setInterestedInBuyout(false)
                                }}
                            >
                                {i18n.t('x-year-distribution-deal', 5)}
                            </Button>
                            <Button
                                color="primary"
                                variant={interestedInBuyout ? "solid" : "outlined"}
                                onClick={() => setInterestedInBuyout(true)}
                            >
                            {i18n.t('full-buyout')}
                            </Button>
                        </ButtonGroup>
                        <br />
                        <Button
                            color="primary"
                            variant="solid"
                            loading={submitting}
                        >
                            {i18n.t('submit')}
                        </Button>
                    </div>
                </form> 
        )
    } else {
        return <ErrorView />
    }
}
