import React from 'react';

import { Route } from 'react-router-dom';

import { Navigate } from 'react-router-dom';
import Layout from './pages/layout';

import dashboardRoutes from './pages/dashboard/routes';
import accountsRoutes from './pages/accounts/routes';
import advanceRoutes from './pages/advance/routes';

import { Outlet } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { createRoutesFromElements } from 'react-router-dom';
import { NotAuthorizedPage } from './pages/dashboard/notauthorized';
import { EmbedLayout } from './pages/embed/layout';


export default createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route path="/embed" element={<EmbedLayout />}>
      {advanceRoutes}
    </Route>
    <Route path="/" element={<Layout />}>
      <Route path="notauthorized" element={<NotAuthorizedPage />} />
      <Route index element={<Navigate to="accounts/login" replace />} />
      {accountsRoutes}
      {advanceRoutes}
      <Route path="a" element={<Outlet />}>
        <Route path=":labelId" element={<Outlet />}>
          {dashboardRoutes}
        </Route>
      </Route>
      {dashboardRoutes}
    </Route>
    </>
  )
)
