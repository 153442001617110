
import React, { useContext, useEffect, useMemo, useState } from "react";
import { groupBy } from "core-js/actual/array/group-by";
 
import { EventFlyOut, FlyOut } from './FlyOut'

import moment from 'moment'

import { createContainer,  VictoryChart,  VictoryAxis, VictoryTooltip, VictoryBar, VictoryArea, Flyout, VictoryLabel, VictoryScatter } from 'victory';

import { ThemeContext } from "@/contexts/ThemeContext";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/joy";

const VictoryZoomVoroniContainer = createContainer("zoom", "voronoi");

const NewReleaseMarker = ({ x, y, datum }) => {
  console.log("colorScheme", datum.object.colorScheme)
  return (
    <g>
      {datum.object.imageUrl && (
        <image
          x={x - 20}
          y={y - 30}
          width={30}
          height={30}
          href={datum.object.imageUrl}
        />
      
      )}
      <text x={x} y={y} fontSize={20}>{datum.action.icon}</text>
    </g>
  )
}

export function sumBy(values, reducer) {
  if (values.length < 1) {
    return []
  }
  let newObj = values[0]
  for (let value of values) {
    if (reducer) {
      newObj = reducer(newObj, value)
      continue
    }
    if (value instanceof Object) { 
      newObj = newObj ?? {} 
      Object.keys(value).map(key => {
        let newVal = value[key]
        let currentVal = newObj[key] ?? 0
        if (reducer instanceof Function) {
          newVal = reducer(newVal, currentVal)
        } else {
          if (!isNaN(newVal) && !isNaN(currentVal)) {
            newVal = newVal + currentVal
          } else {
            
          }
        }
        newObj[key] = newVal
      })
    } else {
      newObj = newObj + value
    }
  }
  return newObj
}

export function LineSeries({
  series,
  timelines,
  start,
  end,
  dataKey,
  width = 700,
  height = 300,
  onZoomDomainChange
}) {
//  const { theme } = useContext(ThemeContext)
  const theme = useTheme()
  const maxValue = useMemo(() => {
    
    let max = 0
    for (let serie of Object.values(series)) {
      for (let value of serie.values) {
        max = Math.max(max, parseFloat(value[dataKey] ?? 0) ?? 0)
      }
    }
    return max
  }, [series]) 
  const [zoomDomain, setZoomDomain] = useState({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue + 200] })
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')
  const color = theme === 'dark' || isDark ? '#888' : '#111'
 
  useEffect(() => {
    setZoomDomain({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue + 200] })
  }, [start])

  useEffect(() => {
    setZoomDomain({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue + 200] })
  }, [end])

  const handleZoomDomainChange = (zoomDomainValue) => { 
    setZoomDomain(zoomDomainValue);
    onZoomDomainChange?.({ values: series[0].values, domain: zoomDomainValue })
    console.log("zoomDomainValue", zoomDomainValue)
  }
  const domain = { x: [moment('2024-01-01').toDate(), moment().toDate()], y: [0, maxValue + 200] }

  return (
    <>
      <VictoryChart
        responsive={true}
        width={width}
        height={height}
        domain={zoomDomain}
        scale={{ x: "time" }} 
        style={{
           
        }}
       
      > 
        <VictoryAxis
          style={{
            tickLabels: {
              fill: color
            }
          }}
        />
        <VictoryAxis
          fixLabelOverlap={true}
          dependentAxis
          style={{
            grid: { stroke: 'rgba(127, 127, 127, .3)', strokeWidth: 0.5 },
            tickLabels: {
              fill: color
            }
          }}
        />
        {series.map((serie, index) => (
          <VictoryArea
            key={serie.id}
            labels={(o) => o[dataKey] ?? 0} 
            labelComponent={
              <VictoryTooltip
                renderInPortal={true} 
                flyoutStyle={{
                  fill: theme.vars.palette.primary.solidBg,
                }}
                style={{
                  fill: 'white',
                }} 
              />
            }
            style={{
              data: { stroke: theme.vars.palette.primary.solidBg, fill: "transparent", strokeWidth: 3 }
            }}
            barRatio={5}
            //barWidth={(value) => {  return (width ) / (value.data.length  ) }}
            x={(value) => {
              return new Date(value.date)
            }}
            y={(value) => {
              return value[dataKey] ?? 0
            }}
            data={serie.values.map(value => ({ 
              date: new Date(value.date),
              [dataKey]: parseFloat(value[dataKey] ?? 0) ?? 0,
              label: `${moment(value.date).format('L')}: ${(value[dataKey] ?? 0 ?? 0).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })} streams`
            }))}
          >
           <VictoryTooltip
              center={{ x: 225, y: 30 }}
              pointerOrientation="bottom"
              flyoutWidth={150}
              flyoutHeight={50}
              pointerWidth={150}
              cornerRadius={0}
            />
          </VictoryArea>
        ))}
        {series.map((serie, index) => (
          <VictoryBar
            key={serie.id}
            labels={(o) => o[dataKey] ?? 0} 
            labelComponent={
              <VictoryTooltip
                renderInPortal={true}
                flyoutComponent={<FlyOut />}
                flyoutStyle={{
                  fill: theme.vars.palette.primary.solidBg,
                }}
                style={{
                  fill: 'white',
                }} 
              />
            }
            style={{
             
              data: { transform: 'translateX(30pt)', stroke: 'transparent', fill: "transparent" }
            }}
            barWidth={110}
            barRatio={0}
            
            //barWidth={(value) => {  return (width ) / (value.data.length  ) }}
            x={(value) => {
              return new Date(value.date)
            }}
            y={(value) => {
              return value[dataKey] ?? 0
            }}
            data={serie.values.map(value => ({ 
              date: new Date(value.date),
              streams: parseFloat(value[dataKey] ?? 0) ?? 0,
              label: `${moment(value.date).format('L')}: ${(value[dataKey] ?? 0 ?? 0).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })} streams`
            }))}
          >
           <VictoryTooltip
              center={{ x: 225, y: 30 }}
              pointerOrientation="bottom"
              flyoutWidth={150}
              flyoutHeight={50}
              pointerWidth={150}
              cornerRadius={0}
            />
          </VictoryBar>
        ))}
        {timelines?.filter(t => t.events?.length > 0).map(timeline => (
          <VictoryScatter
            key={timeline.id}
            size={10}
            symbol={timeline.symbol}
            dataComponent={<NewReleaseMarker />}
            labels={(o) => o[dataKey] ?? 0} 
            labelComponent={
              <VictoryTooltip
                renderInPortal={true}
                flyoutComponent={<EventFlyOut />}
                flyoutStyle={{
                  fill: theme.vars.palette.primary.solidBg,
                }}
                style={{
                  fill: 'white',
                }} 
              />
            }
            style={{
              data: {  fill: primaryColor }
            }}
            y={(e) =>0}
            x={(value) => {
              return new Date(value.date)
            }}
            data={timeline?.events}
          />
        ))}
      </VictoryChart>
    </>
  )
}